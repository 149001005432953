<template>
  <div>
    <b-row class="root-div mx-auto">
      <b-col cols="12" md="5" class="mt-10">
        <span class="font-bold font-20">Box Info</span>
        <div class="black-line" />
        <div class="sub-div center d-flex flex-column" v-if="boxInfo">
          <img :src="boxInfo.photoUrl" :width="200" :height="200" />
          <br/>
          <span class="font-bold font-20">{{ boxInfo.name }}</span>
          <span class="font-15">{{ boxInfo.description }}</span>
          <br/>
          <span class="font-15">{{ boxInfo.detail }}</span>
          <br/>
        </div>
        <div class="py-40 center" v-else>
          <p>Please wait ...</p>
          <b-spinner type="grow"></b-spinner>
        </div>
      </b-col>
      <b-col cols="12" md="7" class="mt-10">
        <span class="font-bold font-20">Proceed to checkout</span>
        <div class="black-line" />
        <div class="sub-div">
          <!-- Location -->
          <div>
            <span class="font-bold font-20 mx-3">Cleaning Address</span>
            <GmapAutocomplete 
              ref="autocompleteRef"
              class="w-100 my-1 p-6 shadow bg-white rounded form-control form-control-solid"
              placeholder="Where are we loading"
              @change="onChanged"
              @place_changed="onPlaceChanged"
              />
          </div>
          <div class="divider mt-5" />
          <div class="m-3 d-flex justify-content-between">
            <span class="font-bold font-15">Total</span>
            <span class="font-bold font-20">{{ boxInfo ? getPriceFormat(Number.parseInt(boxInfo.price) / 100, true) : '' }}</span>
          </div>
          <div class="divider" />
          <p>&nbsp;</p>
          <span class="text-center mx-2">Add additional details. (Apartment, Suite, etc)</span>
          <b-form-textarea
            class="mt-1"
            v-model="note"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          />
          <p>&nbsp;</p>
          <span>* All orders ship the next day and take 3-7 business days</span>
          <label class="mt-5 checkbox checkbox-danger">
            <input type="checkbox" v-model="acceptedTerms" />
            <span></span>
            &nbsp;&nbsp;&nbsp;I agree to the &nbsp;<a :href="termsUrl" target="_blank">MOVX Terms &amp; Conditions.</a>
          </label>
          <v-btn class="mt-5 text-capitalize" color="#EB4C60" @click="setOrderTask" block large v-if="boxInfo && elementsOptions.clientSecret === ''" :disabled="!acceptedTerms">
            <span class="font-bold text-white font-15">Buy Now</span>
          </v-btn>
          <div class="mt-5 bg-white rounded">
            <stripe-element-payment
              v-if="elementsOptions.clientSecret !== ''"
              ref="paymentRef"
              :pk="publishableKey"
              :elements-options="elementsOptions"
              :confirm-params="confirmParams"
            />
          </div>
          <v-btn class="mt-10 text-capitalize" color="#EB4C60" @click="payNow" block large v-if="elementsOptions.clientSecret !== ''">
            <span class="font-bold text-white font-15">Pay Now</span>
          </v-btn>
        </div>
      </b-col>
    </b-row>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/booking.scss";
</style>
<style lang="scss">
  .v-event {
    background-color: #EB4C60;
    color: white;
    margin: 2px;
    padding: 1px;
  }
</style>

<script>
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import { getDateString, getPriceFormat, getDecimalFormat, getTimeString, showLoading } from '../../../functions';
import { functions } from '../../../main';
import { httpsCallable } from 'firebase/functions';

export default {
  name: 'BoxDetail',
  components: {
    KTFooterExtended,
    StripeElementPayment
  },
  computed: {
    myInfo() {
      return this.$store.state.myInfo;
    },
    boxList() {
      return this.$store.state.boxList;
    }
  },
  watch: {
    myInfo() {
      this.setLocationInfo();
    },
    boxList() {
      this.getBoxInfo();
    }
  },
  data() {
    return {
      timeOptions: [],
      elementsOptions: {
        clientSecret: '',
      },
      confirmParams: {
        return_url: window.location.protocol + '//' + window.location.host + '/confirm_payment'
      },
//    publishableKey: 'pk_test_51KoKAZLzoW8WTqK207BztYlHshz73fXEQOGnIBLD8NM97g01WWehygkEtaXeKAlbaHww7QBJhvsR81F8DtJcpFmz00cobAGKST',
      publishableKey: 'pk_live_51KoKAZLzoW8WTqK2D0PbbAFDO8BQ6PnW7d9cQhFZ6DZfCvjAFhpS0VoV9VE5my0tHgf5fGfzr6hec0ds5xyUWQI900ibn4OB8l',
      geoPoint: null,
      address: '',
      note: '',
      start: null,
      end: null,
      events: [],
      boxInfo: null,
      pricingInfo: null,
      visibleDialog: false,
      acceptedTerms: false,
      termsUrl: `${window.location.origin}/terms`
    }
  },
  mounted() {
    this.getBoxInfo();
    this.setLocationInfo();
  },
  methods: {
    getPriceFormat,
    getTimeString,
    getDecimalFormat,
    getBoxInfo() {
      if (this.$route.params.id && this.boxList) {
        const boxInfo = this.boxList.find(element => element.boxId === this.$route.params.id);
        if (boxInfo) {
          this.boxInfo = boxInfo;
        }
      }
    },
    setLocationInfo() {
      if (this.myInfo !== null) {
        this.geoPoint = this.myInfo.geoPoint;
        this.address = this.myInfo.address;
        this.$refs.autocompleteRef.$el.value = this.address;
      }
    },
    onChanged(event) {
      this.$refs.autocompleteRef.$el.value = this.address;
    },
    onPlaceChanged(placeData) {
      if (placeData && placeData.geometry.location) {
        const location = JSON.parse(JSON.stringify(placeData.geometry.location));
        const geoPoint = {latitude: location.lat, longitude: location.lng};
        if (this.checkInUS(placeData.formatted_address) === true) {
          this.geoPoint = geoPoint;
          this.address = placeData.formatted_address;
        } else {
          this.geoPoint = null;
          this.address = '';
        }
        this.$refs.autocompleteRef.$el.value = this.address;
      }
    },
    checkInUS(address) {
      if (address === null || address === '') {
        return false;
      }
      if (address.toUpperCase().includes('USA')) {
        return true;
      } else {
        this.$toast.error('The order can be made within US' + address);
        return false;
      }
    },
    getDistance(geoPoint1, geoPoint2) {
      var radlat1 = Math.PI * geoPoint1.latitude / 180;
      var radlat2 = Math.PI * geoPoint2.latitude /180;
      var theta = geoPoint1.longitude - geoPoint2.longitude;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      return dist * 60 * 1.1515;
    },
    payNow() {
      this.$refs.paymentRef.submit();
    },
    setOrderTask() {
      if (this.myInfo === null) {
        this.$toast.error('Please login first!');
        return;
      }
      if (this.boxInfo === null) {
        this.$toast.error('Unexpected error occurs!');
        return;
      }
      if (this.address === '' || this.geoPoint === null) {
        this.$toast.error('Please enter your location.');
        return;
      }
      if (this.checkInUS(this.address) !== true) {
        return;
      }
      const details = this.boxInfo;
      delete details.updatedAt;
      const params = {
        userId: this.myInfo.userId,
        type: this.ORDER_TYPE_BOX,
        date: getDateString(),
        address: this.address,
        geoPoint: this.geoPoint,
        totalPrice: this.boxInfo.price,
        note: this.note,
        details: details
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setOrderInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (typeof response.data === 'string' || response.data instanceof String) {
          this.elementsOptions.clientSecret = response.data;
        } else {
          this.$toast.error('Unexpected error occurs!');
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>